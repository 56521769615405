import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
  const renderVideoLink = (node)=>{
    console.log(node);
    if(node.frontmatter.video_link) {
      return (
        <span>
          (<a href={node.frontmatter.video_link}>video</a>)
        </span>
      )
    }
  }
  const renderSlidesLink = (node)=>{
    if(node.frontmatter.slide_link){
      return (
        <span>
          (<a href={node.frontmatter.slide_link}>slides</a>)
        </span>
      )
    }
  }
  return (
    <Layout>
      <SEO 
        title="Andrew Louis"
      />
      <div className="page-index container">
        <section>
          <h1>Andrew Louis</h1>
          <p>
            is building a Memex
          </p>
          <p>
            Imagine if your complete personal history was accessible to you in a single, unified archive. Imagine being able to search for something across everything you've messaged, read, or viewed. Imagine using this system to help you make sense of complex topics or navigate connections between information. Imagine using it to help understand yourself.
          </p>
          <p>
            In 1945, <em>The Memex</em> was supposed to be this device but it was never built. Nevertheless, its vision remains with us. 
          </p>
          <p>
            I'm working on building an experimental digital version. <Link to="/memex/">Read more about my Memex project &rarr;
            </Link>
          </p>
        </section>
        <section className="projects">
          <h2>Other projects</h2>
          <p>I've been attempting to photograph and document all the <OutboundLink href="https://duetocovid19.com">COVID-19 store signs</OutboundLink> that have gone up everywhere as a result of the coronavirus pandemic.</p>
          <p>
            On my instagram account, I've been <OutboundLink href="https://instagram.com/hyfen">photographing nothing but doors</OutboundLink> for the last seven years.
          </p>
        </section>
        <section className="connect">
          <h2 id="bio">Bio</h2>
          <p>Andrew Louis is a software developer from Toronto.</p>
        </section>
        {/* <section className="connect">
          <h2>Content</h2>
          <ul>
            <li>
              <Link to="/writing">Writing</Link>
            </li>
            <li>
              <Link to="/travels">travels</Link>
            </li>
            <li>
              <Link to="/photography">Photography</Link>
            </li>
          </ul>
        </section> */}
        <section className="connect">
          <h2 id="connect">Connect</h2>
          <p>
            I'm <OutboundLink href="https://twitter.com/hyfen">@hyfen</OutboundLink> on Twitter.
          </p>
          <p>
            My email address is <OutboundLink href="mailto:andrew@hyfen.net">andrew@hyfen.net</OutboundLink>
          </p>
        </section>
        <section className='talks'>
          <h2>Recent talks</h2>
          <ul>
            {data.allMdx.edges.map((item, i) => (
              <li key={item.node.id}>
                {/* {item.node.frontmatter.date} - {item.node.frontmatter.event_name} {renderVideoLink(item.node)} {renderSlidesLink(item.node)} */}
                {item.node.frontmatter.date} - {item.node.frontmatter.event_name}
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx (
      filter: {fields: {sourceName: {eq: "talks"}}},
      sort:{fields:[frontmatter___date], order:DESC}
    ){
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            event_name
            date(formatString: "YYYY-MM")
            slide_link
            video_link
            title
          }
        }
      }
    }
  }
`

export default IndexPage
